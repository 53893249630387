import { useSelector } from 'react-redux';
import { ThemeProvider as ThemeProviderV4 } from '@material-ui/core';
import { ThemeProvider as ThemeProviderV5 } from '@mui/material';
import { Children } from '@appTypes';
import { FeatureFlag, useFeatureFlags } from '@hooks';
import { getMerchantDetails, getThemeType } from '@redux/selectors';
import { getHeyLightTheme } from './config';
import { createThemes } from './utils';

interface IThemeProvider {
  children: Children;
}

export const ThemeProvider = ({ children }: IThemeProvider) => {
  const {
    logo,
    name: merchantName,
    primaryColour: merchantColor,
  } = useSelector(getMerchantDetails);
  const themeType = useSelector(getThemeType);

  const { flagEnabled } = useFeatureFlags();
  const isHeylightOverwriteByMerchantBrandingEnabled = flagEnabled(
    FeatureFlag.IsHeylightOverwriteByMerchantBrandingEnabled,
  );

  const theme = getHeyLightTheme({
    isHeylightOverwriteByMerchantBrandingEnabled,
    merchantColor,
    merchantName,
    logo,
    themeType,
  });

  const { themeV4, themeV5 } = createThemes(theme);

  return (
    <ThemeProviderV4 theme={themeV4}>
      <ThemeProviderV5 theme={themeV5}>{children}</ThemeProviderV5>
    </ThemeProviderV4>
  );
};
