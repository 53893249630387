import { useSelector } from 'react-redux';
import { getCancelUrl, getOriginatorUuid } from '@redux';
import { CopyBlock } from '../CopyBlock';
import { getI18nKey } from './utils';

export const MerchantRedirectLink = ({ isIntroScreen = false }) => {
  const cancelUrl = useSelector(getCancelUrl);

  const originatorUuid = useSelector(getOriginatorUuid);

  const i18nKey = getI18nKey({
    isIntroScreen,
    originatorUuid,
  });

  return (
    <CopyBlock
      i18nKey={i18nKey}
      sx={{ textAlign: 'center', marginBottom: '16px', lineHeight: '20px' }}
      value={{ cancelUrl }}
    />
  );
};
