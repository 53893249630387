import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SplashScreen } from '@heidi-pay/heidi-component-library/components';
import { MerchantRedirectLink } from '@components/MerchantRedirectLink';
import { useIntervalCountdown, useTranslation } from '@hooks';
import { setIsLoading } from '@redux';
import { Actions } from '@utils';
import { getCurrentAction } from '../../../redux';
import {
  StyledMerchantRedirectContainer,
  StyledSplashScreenContainer,
} from '../IntroScreenSwiss/IntroScreenSwiss';
import { ContractTypeKey } from './enums';

interface IOptions {
  hideLegacyProductLogo?: boolean;
  isCredit?: boolean;
}

interface IIntroScreenItalian {
  options?: IOptions;
  submit: () => void;
  submitAttempted: boolean;
}

export const IntroScreenItalian = ({
  options = {},
  submit,
  submitAttempted,
}: IIntroScreenItalian) => {
  const dispatch = useDispatch();
  const currentAction = useSelector(getCurrentAction);
  const { t } = useTranslation();
  const { hideLegacyProductLogo = false, isCredit = false } = options;
  const isCounting = useIntervalCountdown({ duration: 1500 });

  useEffect(() => {
    if (!isCounting && !isCredit) {
      submit();
    }
  }, [isCounting, isCredit, submit]);

  useEffect(() => {
    if (!submitAttempted && isCredit) {
      dispatch(setIsLoading(false));
      submit();
    }
  }, [isCredit, dispatch, submit, submitAttempted]);

  const actionTypeKey =
    currentAction === Actions.initialiseHeylightItalianBNPL
      ? ContractTypeKey.Bnpl
      : ContractTypeKey.Credit;

  const updatedPluginKey = hideLegacyProductLogo ? '.updated_plugin' : '';

  return (
    <StyledSplashScreenContainer>
      <SplashScreen
        footerText={t(
          `fields.introScreenItalian.${actionTypeKey}${updatedPluginKey}.content`,
        )}
        {...(hideLegacyProductLogo ? { originatorBy: null } : {})}
      />
      <StyledMerchantRedirectContainer>
        <MerchantRedirectLink isIntroScreen={true} />
      </StyledMerchantRedirectContainer>
    </StyledSplashScreenContainer>
  );
};
