import * as Sentry from '@sentry/react';

const defaultTagValue = 'unknown';

export const axiosNetworkErrorCode = 'ERR_NETWORK'; // Network-related issue.

export const OriginatedOnBackendTagKey = 'originated_on_backend';

export const SentrySeverity = Object.freeze({
  debug: 'debug',
  error: 'error',
  fatal: 'fatal',
  info: 'info',
  log: 'log',
  warning: 'warning',
});

export const SentryEnvironments = Object.freeze({
  prod: 'prod',
  qa: 'qa',
  sandbox: 'sandbox',
});

// TODO - HC-13890 revert envs disabled for replays to QA only
const replayDisabledEnvs = [
  SentryEnvironments.qa,
  SentryEnvironments.sandbox,
  SentryEnvironments.prod,
];

export const formatSentryEventTags = ({
  checkoutType,
  merchantName,
  originationChannel,
  sessionUuid,
}) => ({
  checkout_type: checkoutType || defaultTagValue,
  merchant_name: merchantName || defaultTagValue,
  origination_channel: originationChannel || defaultTagValue,
  origination_session_uuid: sessionUuid || defaultTagValue,
  [OriginatedOnBackendTagKey]: false,
});

export const setSentryGlobalScope = tags => {
  Sentry.getCurrentScope().setTags(tags);
};

export const getSentryIntegrations = sentryEnv => {
  const shouldEnableReplays = !replayDisabledEnvs.includes(sentryEnv);

  return [
    Sentry.browserTracingIntegration(),
    ...(shouldEnableReplays
      ? [
          Sentry.replayIntegration({
            maskAllText: true,
            blockAllMedia: true,
          }),
        ]
      : []),
  ];
};

export const toggleReplay = enableReplay => {
  const client = Sentry.getClient();
  if (!client) {
    return;
  }

  if (enableReplay) {
    const existingReplay = client.getIntegration(Sentry.replayIntegration);
    if (existingReplay) {
      return;
    }
    const replay = Sentry.replayIntegration({
      maskAllText: true,
      maskAttributes: true,
      unmask: [
        '.MuiFormLabel-root',
        '.MuiFormHelperText-root',
        '.MuiAlert-standardError',
      ],
    });
    client.addIntegration(replay);
  } else {
    const replay = client.getIntegration(Sentry.replayIntegration);
    replay?.stop();
  }
};

// When debugging we might want to limit replays to a reduced number of orders
export const disableSentryReplay = () => toggleReplay(false);
