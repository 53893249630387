/* eslint-disable react/jsx-no-bind */
import { useCallback, useState } from 'react';
import * as yup from 'yup';
import { Box, Collapse, Divider, FormControl, Typography } from '@mui/material';
import { useTranslation } from '@hooks';
import { FormatterType } from '@utils';
import { formatAmountIfExists } from '@utils/amounts';
import { CopyBlock } from '../../../../components/CopyBlock';
import { SelectPicker } from '../SelectPicker';
import { TextBlockValue } from '../TextBlockValue';
import { ImageContainer } from './components/ImageContainer';
import { IProductSelectProps } from './types';

export const ProductSelect = ({
  error,
  inputRef,
  name,
  onBlur,
  onChange,
  options = [],
  setValue,
  value,
}: IProductSelectProps) => {
  const { i18n, t } = useTranslation();
  const locale = i18n.language;
  const initialProduct = options.find(option => option.uuid === value);
  const [selectedProduct, setSelectedProduct] = useState(initialProduct);

  const handleChange = useCallback(
    event => {
      onChange(event);
      setSelectedProduct(options.find(option => option.uuid === event));
    },
    [onChange, options],
  );

  const productOptions = options.map(({ name: productName, price, uuid }) => ({
    value: uuid,
    text: `${productName} ${formatAmountIfExists(price, locale)}`,
  }));
  const selectPickerOptions = {
    items: productOptions,
  };

  return (
    <FormControl
      component="fieldset"
      error={!!error}
      fullWidth={true}
      size="small"
      variant="outlined"
    >
      <SelectPicker
        complete={!!value}
        displayRawValues={true}
        error={error}
        hasEmptyOption={false} // The empty option comes from BE with a blank uuid
        inputRef={inputRef}
        label={t(`fields.productSelect.${name}.label`)}
        name={name}
        onBlur={onBlur}
        onChange={handleChange}
        options={selectPickerOptions}
        setValue={setValue}
        value={value}
      />
      {selectedProduct?.description ? (
        <Typography sx={{ marginTop: '10px', marginLeft: '16px' }}>
          {selectedProduct.description}
        </Typography>
      ) : null}
      <Collapse in={!!selectedProduct?.priceExcludingResidualValue}>
        <CopyBlock i18nKey="fields.textBlock.mobilezonePaymentLink.priceNoticeMobilezone.content" />
        <Divider sx={{ marginTop: '1.20rem' }} />
        <Box my="1.25rem">
          <TextBlockValue
            name="priceWithoutResidualValue"
            options={{
              currency: selectedProduct?.priceExcludingResidualValue?.currency,
              fontSizeOverride: '18px',
              format: FormatterType.AMOUNT_WITH_CURRENCY,
              large: true,
              spacing: 'small',
            }}
            value={selectedProduct?.priceExcludingResidualValue?.amount}
          />
          <CopyBlock i18nKey="fields.textBlock.paymentLinkFlexPriceNotice.content" />
        </Box>
        <Divider />
      </Collapse>
      <Collapse in={!!selectedProduct?.imageUrl}>
        <ImageContainer
          alt={selectedProduct?.name ?? ''}
          src={selectedProduct?.imageUrl ?? ''}
        />
        <Divider />
      </Collapse>
    </FormControl>
  );
};

ProductSelect.validation = () =>
  yup.string().required('fields.productSelect.required');
