import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { getLocales, getOriginationChannel } from '@redux';
import { getActionSettings, OriginationChannels } from '@utils';
import { MerchantRedirectLink } from '../../../components/MerchantRedirectLink';
import LocalePicker from '../LocalePicker';

export const Footer = () => {
  const locales = useSelector(getLocales);
  const originationChannel = useSelector(getOriginationChannel);
  const { isAbandonDisabled } = getActionSettings();

  const shouldShowMerchantRedirectLink =
    originationChannel === OriginationChannels.ecommerce && !isAbandonDisabled;
  const shouldShowLocalePicker = locales && locales.length >= 2;

  if (!shouldShowLocalePicker && !shouldShowMerchantRedirectLink) {
    return null;
  }

  return (
    <Box
      alignItems="center"
      component="footer"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      m={2}
    >
      <Box
        component="span"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        m={2}
      >
        {shouldShowMerchantRedirectLink ? <MerchantRedirectLink /> : null}
      </Box>
      {shouldShowLocalePicker ? <LocalePicker /> : null}
    </Box>
  );
};
