import PropTypes from 'prop-types';
import { HelpRounded } from '@material-ui/icons';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { CopyBlock } from '../../../components';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    padding: '10px',
    border: '1px solid #ddd',
  },
}));

const HeyLightSizeHelpIcon = styled(HelpRounded)(({ theme }) => ({
  color: theme.palette.primary.main,
  top: '-2px',
  position: 'relative',
  marginLeft: '0px',
  cursor: 'pointer',
  marginBottom: '-4.5px',
  height: '0.6em',
}));

export const HelpTooltip = ({ isPlainText = false, tooltip }) => (
  <HtmlTooltip
    enterTouchDelay={10}
    leaveTouchDelay={10000}
    title={
      isPlainText ? (
        tooltip
      ) : (
        <CopyBlock i18nKey={`fields.tooltip.${tooltip}`} />
      )
    }
  >
    <HeyLightSizeHelpIcon aria-label="help" />
  </HtmlTooltip>
);

HelpTooltip.propTypes = {
  tooltip: PropTypes.string.isRequired,
  isPlainText: PropTypes.bool,
};
